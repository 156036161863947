import { useEffect, useState } from "react";

const useVisibilityChange = () => {
  const [ visibilityState, setVisibilityState ] = useState(document.visibilityState); 

  const handleStateChange = () => setVisibilityState(document.visibilityState)
  
  useEffect(() => {
    document.addEventListener("visibilitychange", handleStateChange);

    return () => {
      document.removeEventListener("visibilitychange", handleStateChange);
    };
  }, []);

  return visibilityState;
}

export default useVisibilityChange;
