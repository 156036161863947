import { endOfDay, format, startOfDay } from "date-fns";
import { getDashboardDataEndpoint } from "./endpoints/dashboard";
import { DashboardGetResponse } from "./types";
import { genericGet } from "services/genericService";

const parseDate = (d: Date | null, type: "start" | "end" = 'start') => d ? format((type === 'start' ? startOfDay : endOfDay)(d), "yyyy-MM-dd'T'HH:mm:ss.SSS'Z'") : "";
const parseStartDate = (d: Date | null) => parseDate(d, 'start');
const parseEndDate = (d: Date | null) => parseDate(d, 'end');

const getDashboardData = async (page: number, pageSize: number, selectedLogs: number[], startDate: Date | null, endDate: Date | null, locationUuid?: string, token?: string): Promise<DashboardGetResponse> => {
  const start = parseStartDate(startDate)
  const end = parseEndDate(endDate)

  return genericGet<DashboardGetResponse>(
    getDashboardDataEndpoint(page, pageSize, selectedLogs, start, end, locationUuid),
    {},
    token
  );
}

export const dashboardService = {
  getDashboardData
};
